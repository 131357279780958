<template>
  <div class="authenticationConfirm-wrap">
    <div class="header">
      <div class="img-wrap">
        <img src="@/assets/imgs/authenticationConfirm.png" alt />
      </div>
      <div class="title">信息确认</div>
    </div>
    <div class="line"></div>
    <div class="checkMsg">
      <div class="checkMsg-title">
        请再次确认系统识别的以下内容，否则认证不通过
      </div>
      <div class="checkMsg-title f13">核对身份信息</div>
      <ul class="person-list">
        <li class="person-item">
          <div class="label">姓名:</div>
          <div class="text">
            <van-field v-model="userFullName" placeholder="请输入姓名" />
          </div>
        </li>
        <li class="person-item">
          <div class="label">身份证:</div>
          <div class="text">
            <van-field v-model="userIdCardNo" placeholder="请输入身份证" />
          </div>
        </li>
      </ul>
    </div>
    <div class="footer">
      <van-button
        type="info"
        :disabled="!userFullName || !userIdCardNo"
        @click="goUrl('/faceRecognition')"
        >确认提交</van-button
      >
    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex'

  export default {
    data() {
      return {
        userFullName: null,
        userIdCardNo: null,
      }
    },
    computed: {
      ...mapState({
        creditMsg: state => state.creditMsg,
      }),
    },
    created() {
      this.userFullName = this.creditMsg.userFullName
      this.userIdCardNo = this.creditMsg.userIdCardNo
    },
    methods: {
      goUrl(url) {
        this.$store.commit('setCreditMsg', {
          userFullName: this.userFullName,
          userIdCardNo: this.userIdCardNo,
          userId: this.creditMsg.userId,
          telephone: this.creditMsg.telephone,
        })
        this.$router.push(url)
      },
    },
  }
</script>
<style lang="less" scoped>
  .authenticationConfirm-wrap {
    background: #fff;
    height: 100%;
    width: 100%;
    .header {
      padding-top: 37px;
      padding-bottom: 24px;
      background-color: #fff;
      .img-wrap {
        width: 120px;
        height: 120px;
        margin: 0 auto 18px;
        img {
          width: 120px;
          height: 120px;
        }
      }
      .title {
        height: 24px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #111a34;
        line-height: 24px;
        text-align: center;
      }
    }
    .line {
      height: 8px;
      background: rgba(248, 249, 251, 1);
    }
    .checkMsg {
      padding: 11px 16px;
      margin-bottom: 24px;
      background-color: #fff;
      .checkMsg-title {
        font-size: 14px;
        color: #111a34;
        line-height: 24px;
        margin-bottom: 4px;
      }
      .f13 {
        font-size: 13px;
      }
      .person-list {
        padding: 4px 0;
        .person-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 46px;
          margin-bottom: 16px;
          .label {
            width: 52px;
            height: 24px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #677283;
            line-height: 24px;
            margin-right: 10px;
          }
          .text {
            flex: 1;
            height: 46px;
            font-size: 13px;
            color: #111a34;
            border: 1px solid #e2e4ea;
            border-radius: 4px;
          }
        }
      }
    }
    .footer {
      position: fixed;
      bottom: @safe-area;
      left: 0px;
      right: 0px;
      box-shadow: 0px -6px 10px 0px rgba(230, 234, 237, 0.3);
      padding: 10px 16px;
    }
    .van-button {
      width: 100%;
      height: 44px;
      background: rgba(22, 118, 255, 1);
      box-shadow: 0px 6px 20px 0px rgba(78, 128, 245, 0.16);
      border-radius: 4px;
      font-size: 16px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 44px;
      text-align: center;
    }
  }
</style>
